import { NextPage } from 'next'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Button } from '@ucheba/ui/components/Button/desktop'
import React, { memo, useMemo } from 'react'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Navigation } from '@ucheba/ui/components/Navigation/desktop'
import SC from '../base/IndexPageCalendar.styles'
import { eventsSearchPath } from '../constants'
import { EventsList } from '../../../components/EventsList/desktop'
import { useIndexPageEventsHeaderCore } from '../bll'
import {
  pathname,
  sectionsEventsNavigationItems,
} from '../../for-abiturients/events/index/constants'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const IndexPageEvents: NextPage = () => {
  const allEventsHref = useMemo(() => `${eventsSearchPath}`, [])

  const { title, activeIndex, onClick, events } = useIndexPageEventsHeaderCore()

  if (!events) return null

  return (
    <Block>
      <Spacing spacing='xlarge' />

      <Heading h2={title} spacing='small' />

      <Spacing spacing='small' />

      <Navigation
        items={sectionsEventsNavigationItems}
        activeIndex={activeIndex}
        onClick={onClick}
      />

      <Spacing spacing='large' />

      {events.length ? <EventsList items={events} href={pathname} /> : null}

      <Spacing spacing='xlarge' />

      <Button block view='outline' color='blue' href={allEventsHref} spa>
        Показать все мероприятия
      </Button>
    </Block>
  )
}

export default memo(IndexPageEvents)
