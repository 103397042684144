import React, { FC, memo } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import Link from 'next/link'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'

import { useSelector } from 'react-redux'
import { articlesSelectors } from '@ucheba/store/articles'
import styled from 'styled-components'
import SC from '../base/IndexPageArticles.styles'
import { articlesTitle } from '../constants'

const { Block } = SC

const ButtonSC = styled(Button)`
  height: auto;
  padding: 0;
`

const IndexPageArticles: FC = () => {
  const articlesEntity = useSelector(articlesSelectors.entity)

  if (!articlesEntity) return null

  const { items } = articlesEntity

  if (!items) return null

  return (
    <Block>
      <Heading h2={articlesTitle} spacing='large' />

      <Grid spacing={['large', 'xlarge']}>
        {items.map((el, index) => (
          <GridCol key={index} uxs={12}>
            <Link passHref href={`/article/${el.id}`}>
              <ButtonSC color='blue' view='link'>
                <Text html={false} textAlign='left'>
                  <Text lineHeight='h3' textAlign='left' fontSize='h3' fontWeight='bold'>
                    {el.title}
                  </Text>

                  {el?.announce && (
                    <>
                      <Spacing spacing='xxsmall' />

                      <Text fontWeight='regular' fontSize='large'>
                        {el.announce}
                      </Text>
                    </>
                  )}
                </Text>
              </ButtonSC>
            </Link>

            <Spacing spacing='large' />
          </GridCol>
        ))}
      </Grid>
    </Block>
  )
}

export default memo(IndexPageArticles)
