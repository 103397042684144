import React, { useCallback } from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Picture } from '@ucheba/ui/components/Picture'
import Link from 'next/link'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { List, ListItem } from '@ucheba/ui/components/List/desktop'
import { Menu, MenuItem } from '@ucheba/ui/components/Menu/desktop'
import { Dropdown } from '@ucheba/ui/components/Dropdown/desktop'
import { getCountForm } from '@ucheba/utils/helpers/number'
import { IInstitutionsRankingsStatsItem } from '@ucheba/store/institutions/rankings/stats/types'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageRatings.styles'
import { useInstitutionsRankingsCore } from '../bll'

const { Block, StyledButton, DropdownItem } = SC

const Image = styled(Picture)`
  max-height: 24px;
  max-width: 100%;
`

const IndexPageRatings: NextPage<IIndexPageProps['content']['ratings']> = (props) => {
  const { title } = props

  const {
    isOpenDropdownMenu,
    visibleRankingsList: list,
    openDropdownMenu,
    getRankingHref,
    getSubRankingHref,
  } = useInstitutionsRankingsCore()

  const dropdownMenu = useCallback(
    (ratingItem: IInstitutionsRankingsStatsItem): JSX.Element => {
      return (
        <Menu>
          {ratingItem?.children.dropdownSubRatings.map((dropdownItem) => (
            <MenuItem key={dropdownItem.id}>
              <Link passHref href={getSubRankingHref(dropdownItem.id, ratingItem.id)}>
                <DropdownItem
                  spa
                  as={Button}
                  color='blue'
                  view='link'
                  onClick={openDropdownMenu(ratingItem.id)}
                >
                  <Text textAlign='left' fontWeight='regular' html={false}>
                    {dropdownItem.name}{' '}
                    <Text as='span' color='blue70'>
                      {`${dropdownItem.institutionCount}&nbsp;${getCountForm(
                        dropdownItem.institutionCount,
                        ['вуз', 'вуза', 'вузов']
                      )}`}
                    </Text>
                  </Text>
                </DropdownItem>
              </Link>
            </MenuItem>
          ))}
        </Menu>
      )
    },
    [getSubRankingHref, openDropdownMenu]
  )

  return (
    <Block>
      <Container>
        <Heading h2={title} spacing='large' />

        <Grid>
          {list.map((ratingItem, ratingIndex) => (
            <GridCol key={ratingIndex} uxs={6}>
              <Image
                alt='логотип организации'
                fallbackUrl={ratingItem?.logo?.fallbackUrl}
                webpUrl={ratingItem?.logo?.webp}
              />

              <Spacing spacing='xsmall' />
              <Text as='h5' html={false} fontSize='h5' fontWeight='semibold'>
                {ratingItem.name}{' '}
                <Text as='span' color='gray50'>
                  {ratingItem.year}
                </Text>
              </Text>
              <Spacing spacing='xxsmall' />
              <List spacing='null'>
                <ListItem>
                  <Link passHref href={getRankingHref(ratingItem.id)}>
                    <StyledButton spa as={Button} size='small' color='blue' view='link'>
                      {`${ratingItem.institutionCount} ${getCountForm(
                        ratingItem.institutionCount,
                        ['вуз', 'вуза', 'вузов']
                      )}`}
                    </StyledButton>
                  </Link>
                </ListItem>

                <Spacing spacing='xxsmall' />

                {ratingItem?.children.visibleSubRatings?.map((subRating) => (
                  <ListItem key={subRating.id}>
                    <Link passHref href={getSubRankingHref(subRating.id, ratingItem.id)}>
                      <StyledButton
                        spa
                        as={Button}
                        size='xsmall'
                        color='blue'
                        view='link'
                      >
                        <Spacing spacing='xxsmall' />

                        <Text textAlign='left' fontWeight='regular' html={false}>
                          {subRating.name}{' '}
                          <Text as='span' color='blue70'>
                            {`${subRating.institutionCount}&nbsp;${getCountForm(
                              subRating.institutionCount,
                              ['вуз', 'вуза', 'вузов']
                            )}`}
                          </Text>
                        </Text>
                      </StyledButton>
                    </Link>
                  </ListItem>
                ))}

                {ratingItem?.children.dropdownSubRatings && (
                  <ListItem>
                    <Dropdown
                      color='blue'
                      title='Все направления'
                      open={isOpenDropdownMenu(ratingItem.id)}
                      menu={dropdownMenu(ratingItem)}
                    />
                  </ListItem>
                )}
              </List>
            </GridCol>
          ))}
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPageRatings
