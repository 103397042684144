import React from 'react'
import { NextPage } from 'next'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { ButtonGroup } from '@ucheba/ui/components/ButtonGroup/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { chooseInstitutionTypeButtons } from '../constants'
import { IIndexPageProps } from '../types'
import { useOnChooseInstitutionTypeHandler } from '../bll'

const IndexPageChooseInstitutionType: NextPage<
  IIndexPageProps['content']['chooseInstitutionTypeDialogContent']
> = (props) => {
  const { title, closeDialog, setInstitutionType } = props

  const onClickHandler = useOnChooseInstitutionTypeHandler({
    closeDialog,
    setInstitutionType,
  })

  return (
    <>
      <Heading h2={title} spacing='large' />

      <ButtonGroup orientation='vertical' spacing='small'>
        {chooseInstitutionTypeButtons.map((button) => (
          <Button
            onClick={() => onClickHandler(button)}
            key={button.value}
            type='submit'
            color='blue'
          >
            {button.buttonText}
          </Button>
        ))}
      </ButtonGroup>
    </>
  )
}

export default IndexPageChooseInstitutionType
