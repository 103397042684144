import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Navigation } from '@ucheba/ui/components/Navigation/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import Image from 'next/image'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { EProgramTypes } from '@ucheba/utils/types/program'
import Link from 'next/link'
import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import SC from '../base/IndexPageSpecialties.styles'
import { IIndexPageProps } from '../types'
import { useSpecialtiesIndexPageCore } from '../bll'
import selectVuzByEgeDesktopImage from '../assets/img__select-vuz-by-ege_desktop.png'
import { SpecialityRubricCard } from '../../../components/SpecialityRubricCard/desktop'
import { sectionsNavigationItems } from '../../for-abiturients/speciality/index/constants'
import { forAbiturients } from '../../../routs'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  margin: ${sv.spacing.uxlarge} 0;
`

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const IndexPageSpecialties: NextPage<IIndexPageProps['content']['specialties']> = (
  props
) => {
  const { title, promoBlock } = props

  const { activeIndex, onClick, activeCategory, items, getSpecialitiesHref } =
    useSpecialtiesIndexPageCore()

  // const href = useChooseInstitutionsHref(institutionType.value as TInstitutionTypeValue)

  const isClient = useOnlyClientRendering()

  return (
    <Block>
      <Container>
        <Heading h2={title} />

        <Navigation
          onClick={onClick}
          activeIndex={activeIndex}
          items={sectionsNavigationItems}
          color='black'
        />

        <Spacing spacing='large' />

        {activeCategory === String(EProgramTypes.higher) ? (
          <Grid>
            <GridCol uxs={18}>
              <Grid>
                {items?.map((specialtiesItem) => (
                  <GridCol uxs={8} key={specialtiesItem.name}>
                    <Link passHref href={getSpecialitiesHref(specialtiesItem.id)}>
                      <SpecialityRubricCard
                        isInstitutions
                        height='100%'
                        {...specialtiesItem}
                      />
                    </Link>
                  </GridCol>
                ))}
              </Grid>
            </GridCol>

            <GridCol uxs={6}>
              {isClient && (
                <Link passHref href={forAbiturients.ege.selectVuz.path}>
                  <StyledBox as={Box} color='blue' spacing='null'>
                    <Box spacing='small'>
                      <Heading h3={promoBlock.title} spacing='xxsmall' />

                      <Description description={promoBlock.description} />

                      <Spacing spacing='small' />

                      <Button size='small' color='gold' block>
                        {promoBlock.buttonText}
                      </Button>
                    </Box>

                    <Image
                      src={selectVuzByEgeDesktopImage}
                      width={268}
                      height={196}
                      alt=''
                    />
                  </StyledBox>
                </Link>
              )}
            </GridCol>
          </Grid>
        ) : (
          <Grid>
            {items?.map((specialtiesItem) => (
              <GridCol uxs={6} key={specialtiesItem.name}>
                <Link passHref href={getSpecialitiesHref(specialtiesItem.id)}>
                  <SpecialityRubricCard
                    isInstitutions={false}
                    height='100%'
                    {...specialtiesItem}
                  />
                </Link>
              </GridCol>
            ))}
          </Grid>
        )}
      </Container>
    </Block>
  )
}

export default IndexPageSpecialties
