import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Grid } from '@ucheba/ui/components/Grid/desktop/Grid'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import Link from 'next/link'
import SC from '../base/IndexPageServices.styles'
import { IIndexPageProps } from '../types'
import bannerEgeImage from '../assets/img__services-ege_desktop.png'
import bannerProfessionImage from '../assets/img__services-profession_desktop.png'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)``

const EgeBanner = styled.div`
  background-image: url(${bannerEgeImage});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  height: 100%;
`

const ProfessionBanner = styled.div`
  background-image: url(${bannerProfessionImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledGridCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const StyledButton = styled.div`
  width: max-content;
`

const IndexPageServices: NextPage<IIndexPageProps['content']['services']> = (props) => {
  const { ege, profession } = props

  return (
    <Block>
      <Container>
        <Grid alignItems='stretch'>
          <GridCol uxs={12}>
            <EgeBanner
              resetLastSpacing={false}
              as={Box}
              color='gold'
              spacing={['large', 'xlarge']}
            >
              <Link href={ege.href} passHref>
                <Grid>
                  <StyledGridCol as={GridCol} uxs={15} lg={17}>
                    <BannerContent>
                      <Heading color='coffee' h2={ege.title} spacing='xxsmall' />

                      <Description color='coffee' description={ege.description} />

                      <Spacing spacing='xlarge' />
                    </BannerContent>

                    <StyledButton as={Button} color='orange'>
                      {ege.buttonText}
                    </StyledButton>
                  </StyledGridCol>
                </Grid>
              </Link>
            </EgeBanner>
          </GridCol>

          <GridCol uxs={12}>
            <ProfessionBanner as={Box} color='blue' spacing={['large', 'xlarge']}>
              <Link href={profession.href} passHref>
                <Grid justifyContent='flex-end'>
                  <StyledGridCol as={GridCol} uxs={15} lg={17}>
                    <BannerContent>
                      <Heading color='white' h2={profession.title} spacing='xxsmall' />

                      <Description color='white' description={profession.description} />

                      <Spacing spacing='xlarge' />
                    </BannerContent>

                    <StyledButton as={Button} color='gold'>
                      {profession.buttonText}
                    </StyledButton>
                  </StyledGridCol>
                </Grid>
              </Link>
            </ProfessionBanner>
          </GridCol>
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPageServices
