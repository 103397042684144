import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { List } from '@ucheba/ui/components/List/desktop'
import { ListItem } from '@ucheba/ui/components/List/desktop/ListItem'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageLogotypes.styles'
import desktopLargeImage from '../assets/img__hero-bg_desktop-large.png'
import desktopSmallImage from '../assets/img__hero-bg_desktop-small.png'

const { Block: BlockSC, Logotype, LogotypesList: LogotypesListSC } = SC

const Block = styled(BlockSC)`
  padding: ${sv.spacing.xxlarge} 0;
  background-color: ${sv.color.navy};
  background-repeat: no-repeat;
  background-position: center bottom;

  ${sm.breakpoint.lgAndUp`
    background-image: url(${desktopLargeImage});
  `};

  ${sm.breakpoint.lgAndDown`
    background-image: url(${desktopSmallImage});
  `};
`

const LogotypesList = styled(LogotypesListSC)`
  align-items: center;
  flex-direction: row;

  & li {
    margin-right: ${sv.spacing.large};
  }

  ${sm.breakpoint.lgAndDown`
    & li:last-child {
      display: none;
    }
  `}
`

const IndexPageLogotypes: NextPage<IIndexPageProps['content']['institutionLogotypes']> = (
  props
) => {
  const { list } = props

  return (
    <Block>
      <Container>
        <LogotypesList spacing='null' as={List}>
          {list.map(({ href, logo }, index) => (
            <ListItem key={index} width='auto' alignItems='center' nowrap>
              <a href={href}>
                <Logotype src={logo} />
              </a>
            </ListItem>
          ))}
        </LogotypesList>
      </Container>
    </Block>
  )
}

export default IndexPageLogotypes
