import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import Image from 'next/image'
import { Description } from '@ucheba/ui/components/Description/desktop'
import Link from 'next/link'
import { IIndexPageProps } from '../types'
import SC from '../base/IndexPageFooter.styles'
import boyImage from '../assets/img__footer-boy.png'
import computerImage from '../assets/img__footer-computer.png'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  padding: ${sv.spacing.uxxlarge} 0;
`

const IndexPageFooter: NextPage<IIndexPageProps['content']['footer']> = (props) => {
  const { title, href, buttonText, twoMillionOfStudents, subtitle, CRMSystem } = props

  return (
    <Block>
      <Container>
        <Grid>
          <GridCol uxs={9}>
            <Link href={href} passHref>
              <Text fontWeight='bold' color='white'>
                {subtitle}
              </Text>

              <Spacing spacing='xsmall' />

              <Heading h1={title} spacing='medium' color='white' />

              <Button color='gold'>{buttonText}</Button>
            </Link>
          </GridCol>

          <GridCol uxs={3} />

          <GridCol uxs={6}>
            <Image src={boyImage} width={83} height={80} alt='' />

            <Spacing spacing='medium' />

            <Heading h3={twoMillionOfStudents.title} spacing='xxsmall' color='yellow' />

            <Description description={twoMillionOfStudents.description} color='white' />
          </GridCol>

          <GridCol uxs={6}>
            <Image src={computerImage} width={91} height={80} layout='fixed' aslt='' />

            <Spacing spacing='medium' />

            <Heading h3={CRMSystem.title} spacing='xxsmall' color='yellow' />

            <Description description={CRMSystem.description} color='white' />
          </GridCol>
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPageFooter
